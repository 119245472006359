import './App.css';

function Roadmap_v2() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>$Bear Roadmap 2024</h1>

                <div className={"App-text"}>
                    <p className={"App-text-header"}><b>Q2</b></p>
                    <p>✅ 1. <a className="App-link" target={"_blank"}
                               href={"https://twitter.com/bearcointon/status/1776879205952995412"}>ICO</a></p>
                    <p>✅ 2. Liquidity pool <a className="App-link" target={"_blank"}
                                              href={"https://twitter.com/bearcointon/status/1776747199344566354"}>deploy</a>
                    </p>
                    <p>✅ 3. <a className="App-link" target={"_blank"}
                               href={"https://twitter.com/bearcointon/status/1776747199344566354"}>Lock</a> LP tokens
                        almost forever</p>
                    <p>✅ 4. <a className="App-link" target={"_blank"}
                               href={"https://twitter.com/bearcointon/status/1778025623358943567"}>Airdrop</a> for ICTN
                        holders and PD, Alex (NOT)</p>
                    <p>✅ 5. <a className="App-link" target={"_blank"}
                               href={"https://twitter.com/bearcointon/status/1780590044287570393"}>#bear2049</a> quest
                    </p>
                    <p>❌️ 6. Burning Bear fest</p>
                    <p>✅ 7. <a className="App-link" target={"_blank"}
                               href={"https://t.me/indicaton_bot"}>@indicaton_bot</a> utility for $Bear token</p>
                    <p>😎️ 8. Making fun</p>

                    <p className={"App-text-header"}><b>Q3</b></p>
                    <p>📆 Planning still in progress, but</p>
                    <p>🏗️ 1. Burning Bear fest</p>
                    <p>🏗️ It can be NFT collection</p>
                    <p>🏗️ And activites for $Bear holders</p>
                    <p>🏗️ Also there will be more <a className="App-link" target={"_blank"}
                                                     href={"https://t.me/indicaton_bot"}>@indicaton_bot</a> integration
                    </p>
                    <p>🐱️ More info in <a className="App-link" target={"_blank"}
                                          href={"https://telegra.ph/indicaton-roadmap-02-07"}>@indicaton roadmap</a></p>
                    <p>😎️ Of course making more fun</p>
                </div>
                <br/>
                <p><b>Old roadmap</b> <a className="App-link" href={"/roadmap"}>is still here</a> for anyone who still thinks memecoin doesn't need an any roadmap.</p>
                <p>We're making the $Bear great. You are the great $Bear community.</p>
                <br/>
                <br/>
                <p><b>Back </b> <a className="App-link" href={"/"}>to main</a></p>
                <br/>
                <br/>
            </header>
        </div>
    );
}

export default Roadmap_v2;

import './App.css';
import './roadmap.css';

function Roadmap() {
    return (
        <div className="App">
            <div className={"videowrapper"}>
                <header className="App-header">
                    <iframe width="560" height="315"
                            src="https://www.youtube.com/embed/dQw4w9WgXcQ?si=6sCp_6TpLgnbFcy6&autoplay=1"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </header>
            </div>
        </div>
    );
}

export default Roadmap;

import logo from './logo.png';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <a
                    // className="App-link"
                    href="https://dedust.io/swap/TON/BEAR"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={logo} className="App-logo" alt="logo"/>
                </a>
                <p><b>$Bear</b></p>
                <p onClick={() => {navigator.clipboard.writeText("EQBtv7I3pZpMAUChef2Vrk-U1GcCE00rROuHgMJ9CvjodRZW")}}><b>Contract:</b> EQBtv7I3pZpMAUChef2Vrk-U1GcCE00rROuHgMJ9CvjodRZW</p>
                <p><b>Tokenomics:</b> 50% launch, 48% DEX liquidity (100% TON), 2% airdrop</p>
                <p><b>Roadmap:</b> making $Bear <a className="App-link" href={"/roadmap_v2"}>great</a></p>
                <p><a className="App-link" target="_blank" rel="noopener noreferrer"
                      href={"https://tonraffles.app/lock/EQCaAVhJU-jiX_CcB9ErMyqsacaapxzQOB5umqW7PzEgTHJk/EQC6lGJYc-Z6MiW3TaDGFDn68084VD3cGXm95BX6z2X8Y7tD"}>LP</a> is
                    locked for the loooong time</p>
                <br/>
                <a
                    className="App-link"
                    href="https://dedust.io/swap/TON/EQBtv7I3pZpMAUChef2Vrk-U1GcCE00rROuHgMJ9CvjodRZW"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    buy
                </a>
                <br/>
                <a
                    className="App-link"
                    href="https://t.me/bearcointon"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    telegram channel
                </a>
                <a
                    className="App-link"
                    href="https://t.me/beardaoco"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    telegram chat
                </a>
                <a
                    className="App-link"
                    href="https://twitter.com/bearcointon"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    x (ex twitter)
                </a>
                <br/>
                <br/>
            </header>
        </div>
    );
}

export default App;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import App from "./App";
import Roadmap from "./roadmap";
import Roadmap_v2 from "./roadmap_v2";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
        <Routes>
            <Route path='/' element={<App/>}/>
            <Route path='/roadmap' element={<Roadmap/>}/>
            <Route path='/roadmap_v2' element={<Roadmap_v2/>}/>
        </Routes>
    </Router>
);
